@media (min-width: 900px) {
  body {
    background-color: #fcfefe;
  }

  .header {
    height: 215px;
    background-image: linear-gradient(279deg, #764ba2, #667eea);
    box-shadow: 0 9px 50px 3px #00000012;
  }

  .header-logo {
    height: 20px;
    width: 85px;
    display: block;
    position: absolute;
    right: 1rem;
  }

  .header-content {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    display: flex;
  }

  .header .searchbox-container {
    height: initial;
    width: 100%;
    margin: 2rem auto;
    padding: 0 .5rem;
  }

  .header .magnifier-icon {
    height: 17px;
    min-width: 17px;
    margin-right: 1rem;
    display: inline;
  }

  .header .ais-SearchBox-input {
    color: #fff;
    padding: .5rem .5rem .5rem 0;
    font-size: 1.25rem;
  }

  .header .ais-SearchBox-reset {
    display: inherit;
  }

  .header .ais-SearchBox-resetIcon {
    fill: #fff;
    height: .75rem;
    opacity: .5;
    width: .75rem;
  }

  .header .ais-SearchBox-reset:hover .ais-SearchBox-resetIcon {
    opacity: 1;
  }

  .header .ais-SearchBox-input::placeholder {
    color: #ffffffa8;
    opacity: 1;
  }

  .header .ais-SearchBox-input:-ms-input-placeholder {
    color: #ffffffa8;
  }

  .header .ais-SearchBox-input::-moz-placeholder {
    color: #ffffffa8;
  }

  .container {
    padding: 2.5rem;
  }

  .container-filters-button {
    padding: inherit;
    margin-top: inherit;
    margin-left: inherit;
  }

  .container-filters-button svg {
    height: 13px;
    width: 16px;
    margin-right: .25rem;
  }

  .header .ais-CurrentRefinements-category, .header .ais-RefinementList-labelText {
    color: #6634d1;
    background: #f3f3f3;
    border-radius: 3px;
  }

  .topics-container {
    margin-left: inherit;
    overflow: inherit;
    display: block;
  }

  .topics-container .ais-RefinementList {
    padding-right: 0;
  }

  body.filtering {
    overflow: inherit;
  }

  .card {
    box-shadow: 0 3px 50px 7px #00000012;
  }

  .card-content {
    padding: 1rem;
  }

  .card-title {
    margin: .5rem 0;
  }

  .card-image img {
    border-radius: initial;
  }

  [data-layout="mobile"] {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .infinite-hits-item--highlighted {
    grid-column: 1 / 4;
  }

  .infinite-hits-item--highlighted .card {
    height: auto;
    flex-direction: row;
  }

  .infinite-hits-item--highlighted .card-image {
    flex: 1;
  }

  .infinite-hits-item--highlighted .card-image, .infinite-hits-item--highlighted .card-image img {
    max-height: unset;
  }

  .infinite-hits-item--highlighted .card-content {
    padding: 2rem 3.75rem 2rem 2rem;
  }

  .infinite-hits-item--highlighted .card-title {
    font-size: 1.375rem;
    line-height: 1.36;
  }
}

.container-filters-panel-column--dates .ais-Panel-header {
  padding-bottom: 1.5rem;
}

/*# sourceMappingURL=index.a8d6b104.css.map */
